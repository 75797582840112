<template>
  <div id="homepage">
    <Header />
		<HomePageRender />
  </div>
</template>

<script>
// import Panzoom from '@panzoom/panzoom'
import Header from '@/components/Header.vue'
import HomePageRender from '@/components/render/hompage.vue'

export default {
  name: 'Homepage',
  components: {
    Header,
		HomePageRender
  }, 
  data() {
		return {
			panzoom: null
		}
  },
  methods: {
    mouseOverDot: function(){
		document.getElementById("dot_pin").style.opacity = "0";
		document.getElementById("building").style.opacity = "0.5";
	},
	mouseLeaveDot: function() {
		document.getElementById("dot_pin").style.opacity = "0";
		document.getElementById("building").style.opacity = "0";
	},
	mouseOverBuilding: function() {
		document.getElementById("dot_pin").style.opacity = "0";
		document.getElementById("building").style.opacity = "0.5";
	},
	mouseLeaveBuilding: function() {
		document.getElementById("dot_pin").style.opacity = "1";
		document.getElementById("building").style.opacity = "0";
	},
	frontView: function() {
		this.$router.push({ path: '/frontview' })
	}
  },
	mounted() {
		let logo = new Image();
		logo.src = require("@/assets/emerald-hill-logo-modal.svg");

		let planSiriImg = new Image();
		planSiriImg.src = "img/siri-plan.svg";

		/*
		if (window.innerWidth < 768) {
			const elem = document.getElementById('mainSvg');
			this.panzoom = Panzoom(elem, {contain: 'outside', startScale: 1.2});
			this.panzoom.pan(300, 0)
			console.log("Kreiran panzoom")
		}

		window.addEventListener('resize', function() {
			if (window.innerWidth < 768) {
				const elem = document.getElementById('mainSvg');
				this.panzoom = Panzoom(elem, {contain: 'outside', startScale: 1.2});
				this.panzoom.pan(-600, 0)
				console.log("Rekreiran panzoom");
			} else {
				if (this.panzoom) {
					this.panzoom.destroy()
				}
			}
		});	
		*/
  }
}
</script>
