<template>
  <b-modal id="contactModal" centered hide-footer>
    <img src="@/assets/emerald-hill-logo-modal.svg" class="mx-auto d-block modalLogo" alt="emerald_hill_logo">
    
    <div style="font-size: 14px;" v-show="contactFormContainer">
      <div class="row">
        <div class="col-md-8 offset-md-2 text-center my-4" >
          Ukoliko ste zainteresovani za informacije u vezi sa izgradnjom naših objekata, molimo vas popunite formular i naš tim će Vas kontaktirati u najkraćem vremenskom roku.
        </div>
        <b-form @submit="sendContact">
        <div class="col-md-6 offset-md-3 mb-4">
          <b-form-group label="Ime*">
            <b-form-input id="contactFirstName" :state="contactNameState" />
          </b-form-group>
        </div>

        <div class="col-md-6 offset-md-3 mb-4">
          <b-form-group label="Prezime*">
            <b-form-input id="contactLastName" :state="contactSurnameState" />
          </b-form-group>
        </div>

        <div class="col-md-6 offset-md-3 mb-4">
          <b-form-group label="Ime firme">
            <b-form-input id="contactCompany" />
          </b-form-group>
        </div>

        <div class="col-md-6 offset-md-3 mb-4">
          <b-form-group label="Broj telefona*">
            <b-form-input id="contactPhone" :state="contactPhoneState" />
          </b-form-group>
        </div>

        <div class="col-md-6 offset-md-3 mb-4">
          <b-form-group label="Email*">
            <b-form-input id="contactEmail" :state="contactEmailState" />
          </b-form-group>
        </div>

        <div class="col-md-6 offset-md-3 mb-4">
          <b-form-checkbox 
            id="contactAcceptPrivacyPolicy" 
            value="accepted"  
            button-variant="info" 
            unchecked-value="not_accepted" 
            :state="contactAcceptPrivacyPolicyState"
            html-field="">
            Slažem se sa <a href="https://emeraldhill.rs" target="_blank">uslovima privatnosti i obrade podataka</a>
          </b-form-checkbox>
        </div>

        <div class="col-md-6 offset-md-3 mb-4">
          <b-button type="submit" variant="primary" @click="sendContact" :disabled="showSpinner">
            <span v-if="showSpinner">
              <b-spinner small v-show="showSpinner"></b-spinner> Šaljem podatke
            </span>
            <span v-else>
              Registruj interesovanje
            </span>
          </b-button>
        </div>
        </b-form>
      </div>
    </div>
    <!-- response -->
    <div style="font-size: 14px" v-show="!contactFormContainer">
      <div class="row">
       <div class="col-md-8 offset-md-2 text-center mt-4" >
          Hvala Vam što ste popunili formular.
        </div>
        <div class="col-md-8 offset-md-2 text-center my-4" >
          Naš <strong>Emerald Hill Smart Zone Team</strong> će Vas kontaktirati u najkraćem vremenskom roku.
        </div>
        <div class="col-md-6 offset-md-3 mb-4">
          <b-button type="submit" variant="primary" @click="closeModal">Zatvori</b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BForm, BFormInput, BFormGroup, BSpinner, BFormCheckbox } from 'bootstrap-vue'
import axios from 'axios';

export default {
  name: "ContactModal",
  components: {
    BForm, BFormInput, BFormGroup, BSpinner, BFormCheckbox
  },
  data() {
    return {
      contactFormContainer: true,
      contactNameState: null,
      contactSurnameState: null,
      contactPhoneState: null,
      contactEmailState: null,
      contactAcceptPrivacyPolicyState: null,
      showSpinner: false
    }
  },
  methods: {
    async sendContact(event) {
      event.preventDefault();
      if (this.validateForm()) {
        this.showSpinner = true;
        let formData = new FormData();
        formData.append("cfn", document.getElementById("contactFirstName").value);
        formData.append("cln", document.getElementById("contactLastName").value);
        formData.append("cc", document.getElementById("contactCompany").value);
        formData.append("cp", document.getElementById("contactPhone").value);
        formData.append("ce", document.getElementById("contactEmail").value);
       
        await axios.post( "/sender/", formData).then(() => {
          //console.log(r);
          this.showSpinner = false;
          this.contactFormContainer = false
        });
        /*
        setTimeout(() => {
          this.showSpinner = false;
          this.contactFormContainer = false
        }, 2000);
        */
      }
    },

    validateForm() {
      if (document.getElementById("contactFirstName").value === "") {
        this.contactNameState = false;
        document.getElementById("contactFirstName").focus();
        return false;
      } else {
        this.contactNameState = null;
        document.getElementById("contactFirstName").classList.add("inputValid");
      }

      if (document.getElementById("contactLastName").value === "") {
        this.contactSurnameState = false;
        document.getElementById("contactLastName").focus();
        return false;
      } else {
        this.contactSurnameState = null;
        document.getElementById("contactLastName").classList.add("inputValid");
      }
      
      document.getElementById("contactCompany").classList.add("inputValid");

      if (document.getElementById("contactPhone").value === "") {
        this.contactPhoneState = false;
        document.getElementById("contactPhone").focus();
        return false;
      } else {
        this.contactPhoneState = null;
        document.getElementById("contactPhone").classList.add("inputValid");
      }

      if (document.getElementById("contactEmail").value === "") {
        this.contactEmailState = false;
        document.getElementById("contactEmail").focus();
        return false;
      } else {
        this.contactEmailState = null;
        document.getElementById("contactEmail").classList.add("inputValid");
      }
      return true;
    },

    closeModal() {
      this.$root.$emit('bv::hide::modal', 'contactModal');
      this.contactFormContainer = true;
    }
  }
}
</script>

<style>

</style>