<template>
  <div id="app">
    <div id="content">
      <transition :name="transitionName" mode="out-in">
        <router-view></router-view>
      </transition>
      <img class="fullscreen_icon" src="@/assets/fullscreen_icon.svg" @click="toggleFullScreen" alt="fullscreen icon">
      <Notification v-if="showInfo" />
      <ContactModal />
    </div>
  </div>
</template>

<script>

import ContactModal from "@/components/ContactModal.vue";
import Notification from '@/components/Notification.vue'

export default {
  name: 'App',
  data () {
    return { 
      transitionName: null,
      fullScreenMode: false,
      showInfo: true
      // width: window.innerWidth,
      // height: window.innerHeight
    }
  },
  components: {
    ContactModal,
    Notification
  },
  watch: {
    '$route' (to, from) {
      // console.log(from);
      // console.log(to);
      if (from.path === '/') {
        this.transitionName = 'zoom';
      } else {
        this.transitionName = 'fade';
      }
      if (to.path !== "/") {
        this.showInfo = false;
      } else {
        this.showInfo = true;
      }
    }
  },
  methods: {
    toggleFullScreen: function() {
      const elem = document.documentElement;

      if (this.fullScreenMode === false) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
          this.fullScreenMode = true;
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
          this.fullScreenMode = true;
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
          this.fullScreenMode = true;
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
           this.fullScreenMode = false;
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
          this.fullScreenMode = false;
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
          this.fullScreenMode = false;
        }
      }
    }
  },
  mounted() {
    /* Preload logoa za kontakt */
    let contactModalLogo = new Image();
		contactModalLogo.src = require("@/assets/emerald-hill-logo-modal.svg");

    let totemInfo = new Image();
		totemInfo.src = require("/public/img/totem-info.png");
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #111827;
}

.zoom-leave-active  {
  animation-duration: 0.8s;
  animation-name: zoom;
  animation-direction: reverse;
}


.fade-leave-active{
  transition: opacity .5s ease-out;
}
.fade-enter-active {
  transition: opacity .5s ease-out;
}
.fade-enter, .fade-leave-to {
  opacity: 0.1
}

@keyframes zoom {
  from {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
    transform: scale3d(1.2, 1.2, 1.2);
  } 

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
</style>
