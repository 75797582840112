<template>
  <div v-if="showPopup" class="container notificationContainer d-sm-inline d-md-none npShow" id="myNotificationPopup">
		<section class="justify-content-center">
			<div class="notification gallery justify-content-center">
        <div class="popupCloseIcon" @click="closeNotificationPopup"><b-icon-x></b-icon-x></div>
        <div class="verticalAlign notificationInfoIcon"><b-icon-info-circle></b-icon-info-circle></div>
				<div class="notificationContent">
					<p>Za sadržajniji prikaz, posetite 3d-smartzone.com stranicu preko Vašeg laptop ili desktop računara.</p>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
  name: 'Notification',
  data() {
    return {
      showPopup: true
    }
  },
  created() {
    // Ako u lokal storiđu postoji stavka notificationPopup i ako ima vrednost cl
    // popup se ne prikazuje
    if (typeof window.localStorage.getItem("notificationPopup") !== "undefined" && window.localStorage.getItem("notificationPopup") === 'cl') {
      this.showPopup = false;
    } else {
      this.showPopup = true
    }

  },
  methods: {
      closeNotificationPopup: function() {
        document.getElementById("myNotificationPopup").className = "npHidden";
        window.localStorage.setItem("notificationPopup", "cl");
      }
  }
}
</script>
<style scoped>
</style>