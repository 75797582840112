import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Routes from './routes'

// import { BootstrapVue, BootstrapVueIcons  } from 'bootstrap-vue'

import { 
  ModalPlugin, 
  ButtonPlugin,
  BIconX, 
  BIconEnvelope, 
  BIconTelephone, 
  BIconArrowLeft, 
  BIconInfoCircle 
} from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/main.css'

/* Učitavamo samo one komponente koje se koriste, a ne ceo BoostrapVue i BootstrapVueIcons. */
// Make BootstrapVue available throughout your project
//Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
//Vue.use(BootstrapVueIcons)

Vue.use(ModalPlugin);
Vue.use(ButtonPlugin)
Vue.component('BIconX', BIconX)
Vue.component("BIconEnvelope", BIconEnvelope);
Vue.component("BIconTelephone", BIconTelephone);
Vue.component("BIconArrowLeft", BIconArrowLeft);
Vue.component("BIconInfoCircle", BIconInfoCircle);

Vue.use(VueRouter)
const router = new VueRouter({
  routes: Routes
});

Vue.config.productionTip = false

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
