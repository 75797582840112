<template>
<b-modal id="modal-1" hide-footer size="lg">
    <img src="@/assets/emerald-hill-logo-modal.svg" class="mx-auto d-block modalLogo" alt="emerald_hill_logo">

		<div class="lokacijaPopup">
			<p>Lokacija: Gravrila Principa 22, Novi Sad</p>
		</div>

		<div class="row modalContent">
			<div class="row divInfo">
				<div class="col-md-4 verticalAlign">
					<div class="modalInfo">
						<h2 class="naslov" id="naslov">{{ prostor.naslov }}</h2>
						<p id="sprat">{{ prostor.sprat }}</p>
						<div id="povrsina">{{ prostor.povrsina }}</div>
					</div>
					<div class="dashSeparator"></div>
					<div class="modalContact">
						<h2 class="naslov">Kontakt</h2>
						<div class="contactLink"><b-icon-telephone></b-icon-telephone><a class="contactPopupInfo" href="tel:+381648208603">+381 64 820 8603</a></div>
						<div class="contactLink"><b-icon-envelope></b-icon-envelope><a class="contactPopupInfo" href="mailto:smartzone@emeraldhill.rs">smartzone@emeraldhill.rs</a></div>
					</div>
				</div>
				<div class="col-md-8">
					<a :href="prostor.plan_prostor" target="_blank">
						<img id="plan_prostor" :src="prostor.plan_prostor" class="img-fluid" alt="Plan prostora"/>
					</a>
					<template v-if="prostor.izdatoImg !== ''">
						<img :src="prostor.izdatoImg" id="izdatoImg" alt="izdato">
					</template>
				</div>
			</div>
			<div class="planImages">
				<div class="row planDiv pt-4">
					<div class="col-md-6 pb-4">
						<h2 class="naslov">Plan širi</h2>
						<img id="slika_plan_siri" :src="prostor.slika_plan_siri" class="img-fluid modalPlanSlika" alt="Širi plan" />
					</div>
					<div class="col-md-6">
						<h2 class="naslov">Plan parcele</h2>
						<img id="slika_plan_parcele" :src="prostor.slika_plan_parcele" class="img-fluid modalPlanSlika" alt="Plan parcele" />
					</div>
				</div>
			</div>
			<div class="col-md-12 footerImg">
				<img id="slika_prostor" :src="prostor.slika_prostor" class="img-fluid mx-auto d-block" alt="Slika prostora" />
			</div>
			<div class="col-md-12">
				<p class="modal-footer-text">Napomena: Svi materijali, dimenzije i crteži su okvirni. Kalkulacija površina unutrašnjeg i spoljašnjeg prostora urađena je u skladu sa važećim standardom (SRPS U.C2.100:2002) i predstavlja neto površinu stambene jedinice.</p>
			</div>
		</div>
</b-modal>
</template>
<script>

export default {
  name: 'ModalDetails',
  props: {
		prostor: Object
  },
  created() {
	
  }

}

</script>