<template>
  <b-modal id="modal-2" hide-footer size="lg">
      <img src="@/assets/emerald-hill-logo-modal.svg" class="mx-auto d-block modalLogo" alt="emerald_hill_logo">
      <h2 class="text-center mt-4 mb-1">List of Facilities</h2>
      <div class="lokacijaPopup">
        <p>Lokacija: Gravrila Principa 22, Novi Sad</p>
      </div>
      <div class="facilities">
        <div class="row m-3">
          <div class="col-12 text-center">
            <img src="/img/totem-info.png" alt="Conference facilities" class="img-fluid">
          </div>
<!--           <div class="col-3 offset-1 d-flex align-items-center">
              <p>Conference facilities</p>
          </div>
          <div class="col-3 d-flex align-items-center justify-content-center">
              <SmallCircle />
              <hr class="hori" />
              <svg  class="img-fluid" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
               <rect x="1" y="1" width="48" height="48" rx="16" fill="#F9FAFB"/>
               <path d="M16 18V32H34V18H16ZM21.33 30H18V20H21.33V30ZM26.67 30H23.34V26H26.67V30ZM32 30H28.67V26H32V30ZM32 24H23.33V20H32V24Z" fill="#36939B"/>
               <rect x="1" y="1" width="48" height="48" rx="16" stroke="#D1D5DB" stroke-width="2"/>
              </svg>
          </div>
        </div>
        <hr class="vert" /> 

        <div class="row ">
          <div class="col-sm-3 offset-4 d-flex align-items-center  justify-content-center">
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1" y="1" width="48" height="48" rx="16" fill="#F9FAFB"/>
              <path d="M32 17H31V15H29V17H21V15H19V17H18C16.89 17 16.01 17.9 16.01 19L16 33C16 34.1 16.89 35 18 35H32C33.1 35 34 34.1 34 33V19C34 17.9 33.1 17 32 17ZM32 33H18V23H32V33ZM32 21H18V19H32V21ZM25 26H30V31H25V26Z" fill="#36939B"/>
              <rect x="1" y="1" width="48" height="48" rx="16" stroke="#D1D5DB" stroke-width="2"/>
            </svg>
            <hr class="hori" />
            <SmallCircle />
          </div>
          <div class="col-4 d-flex align-items-center">
          <p>Event and multimedia hall</p>
          </div>
        </div>

        <hr class="vert" /> 

        <div class="row d-flex align-items-center">
          <div class="col-4 offset-1">Reliable, fast Wi-Fi</div>
          <div class="col-2 text-center">
          <svg class="img-fluid" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="48" height="48" rx="16" fill="#F9FAFB"/>
            <path d="M14 21.9998L16 23.9998C20.97 19.0298 29.03 19.0298 34 23.9998L36 21.9998C29.93 15.9298 20.08 15.9298 14 21.9998ZM22 29.9998L25 32.9998L28 29.9998C26.35 28.3398 23.66 28.3398 22 29.9998ZM18 25.9998L20 27.9998C22.76 25.2398 27.24 25.2398 30 27.9998L32 25.9998C28.14 22.1398 21.87 22.1398 18 25.9998Z" fill="#36939B"/>
            <rect x="1" y="1" width="48" height="48" rx="16" stroke="#D1D5DB" stroke-width="2"/>
          </svg>
          </div>
          <div class="col-4">&nbsp;</div>
        </div>

        <hr class="vert" /> 

        <div class="row d-flex align-items-center">
          <div class="col-4 offset-1">&nbsp;</div>
          <div class="col-2 text-center">
            <svg class="img-fluid" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="48" height="48" rx="16" fill="#F9FAFB"/>
            <path d="M26 16H19V34H23V28H26C29.31 28 32 25.31 32 22C32 18.69 29.31 16 26 16ZM26.2 24H23V20H26.2C27.3 20 28.2 20.9 28.2 22C28.2 23.1 27.3 24 26.2 24Z" fill="#36939B"/>
            <rect x="1" y="1" width="48" height="48" rx="16" stroke="#D1D5DB" stroke-width="2"/>
            </svg>
          </div>
          <div class="col-4 text-end">Easy access to parking and transportation</div>
        </div>

        <hr class="vert" /> 
 -->
        </div>
      </div>
  </b-modal>
</template>

<script>

export default {
  name: 'ModalTotem',
  created() {
  }
}

</script>