/*
import Homepage from '@/components/Homepage.vue'
import FrontView from '@/components/FrontView.vue'
import BackView from '@/components/BackView.vue'
*/

/* 
    Sve se kompajlira u jedan ogroman app.js fajl. Ima oko 9MB.
    Pomoću webpackChunkName-a razdvajamo putanje (komponente) 
    u 3 različita js fajla.
    U kombinaciji uključenim gzip-om, dobija se brzini učitavanja.
 */
export default [
    { path: '/', component: () => import(/* webpackChunkName: "Homepage" */ '@/components/Homepage.vue')},
    { path: '/frontview', component: () => import(/* webpackChunkName: "FrontView" */ '@/components/FrontView.vue')},
    { path: '/backview', component: () => import(/* webpackChunkName: "BackView" */ '@/components/BackView.vue')}
]

