<template>
    <div class="container headerContainer">
		<header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between pt-2 pb-4 border-bottom">
			<div class="col-4 text-start">
				<router-link to="/" v-if="showbackbutton" class="btn btn-link mr-2"><b-icon-arrow-left></b-icon-arrow-left>
					<span class="d-none d-md-inline">Početna stranica</span>
				</router-link>
			</div>

			<div class="col-4 text-center">
				<a href="https://www.emeraldhill.rs/" class="col-md-auto mb-2 justify-content-center mb-md-0 logo">
					<img src="@/assets/emerald-hill-logo.svg" alt="Emerald Hill logo" class="img-fluid mainlogo">
				</a>
			</div>

			<div class="col-4 text-end">
				<button type="button" @click="contactPopup" class="btn btn-link me-2"><b-icon-telephone></b-icon-telephone><span class="d-none d-md-inline">Kontaktirajte nas</span></button>
			</div>
		</header>
		<div class="contactPopup">
			<div id="myContactPopup" class="popuptextcontact">
				<div class="contactPopupTextData">
					<div class="popupCloseIcon" @click="closeContactPopup"><b-icon-x></b-icon-x></div>
					<img src="@/assets/smart-zone-logo.svg" alt="smart zone logo">
					<div class="modalContact">
						<h2 class="naslov">Kontakt</h2>
						<div class="contactLink"><b-icon-telephone></b-icon-telephone><a class="contactPopupInfo" href="tel:+381648208603">+381 64 820 8603</a></div>
						<div class="contactLink"><b-icon-envelope></b-icon-envelope><a class="contactPopupInfo" href="mailto:smartzone@emeraldhill.rs">smartzone@emeraldhill.rs</a></div>
						<b-button variant="light" class="mb-2" @click="openContactModal">Registruj interesovanje</b-button>
					</div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
	return {
		showbackbutton: false
	}
  },
  created: function(){
		if(this.$route.path !== '/') {
			this.showbackbutton = true;
		} else {
			this.showbackbutton = false;
		}
  },
  methods: {
		contactPopup: function() {
			var myContactPopup = document.getElementById("myContactPopup");
			myContactPopup.classList.add("show");
		},
		closeContactPopup: function() {
			var popup = document.getElementById("myContactPopup");
			popup.classList.remove("show");
		},
		openContactModal() {
			this.$root.$emit('bv::show::modal', 'contactModal')
		}
	}
}
</script>