<template>
    <div class="container galleryContainer">
			<div class="row text-center">
				<div class="col-sm-12">
						<h3 id="galleryTitle">Foto galerija</h3>
				</div>
			</div>
			<section>
				<div class="row gallery justify-content-center">
					
					<div class="col-md-1 col-2 thumb">
						<a href="/gallery/hleb_eksterijer_01.jpeg" title="HLEB-Eksterijer-01">
							<figure><img class="img-fluid img-thumbnail" src="/gallery/HLEB-Eksterijer-01-thumb.jpeg" alt="Image"></figure>
						</a>
					</div>

					<div class="col-md-1 col-2 thumb">
						<a href="/gallery/hleb_eksterijer_02.jpeg" title="HLEB-Eksterijer-02">
							<figure><img class="img-fluid img-thumbnail" src="/gallery/HLEB-Eksterijer-02-thumb.jpeg" alt="Image"></figure>
						</a>
					</div>

					<div class="col-md-1 col-2 thumb">
						<a href="/gallery/hleb_eksterijer_03.jpeg" title="HLEB-Eksterijer-03">
							<figure><img class="img-fluid img-thumbnail" src="/gallery/HLEB-Eksterijer-03-thumb.jpeg" alt="Image"></figure>
						</a>
					</div>

					<div class="col-md-1 col-2 thumb">
						<a href="/gallery/hleb_eksterijer_04.jpeg" title="HLEB-Eksterijer-04">
							<figure><img class="img-fluid img-thumbnail" src="/gallery/HLEB-Eksterijer-04-thumb.jpeg" alt="Image"></figure>
						</a>
					</div>

					<div class="col-md-1 col-2 thumb">
						<a href="/gallery/HLEB-Enterijer-01-05-09.jpeg" title="HLEB-Enterijer-01-05-09">
							<figure><img class="img-fluid img-thumbnail" src="/gallery/HLEB-Enterijer-01-05-09-thumb.jpeg" alt="Image"></figure>
						</a>
					</div>

					<div class="col-md-1 col-2 thumb">
						<a href="/gallery/HLEB-Enterijer-02-06-10.jpeg" title="HLEB-Enterijer-02-06-10">
							<figure><img class="img-fluid img-thumbnail" src="/gallery/HLEB-Enterijer-02-06-10-thumb.jpeg" alt="Random Image"></figure>
						</a>
					</div>

					<div class="col-md-1 col-2 thumb">
						<a href="/gallery/HLEB-Enterijer-03-07-11.jpeg" title="HLEB-Enterijer-03-07-11">
							<figure><img class="img-fluid img-thumbnail" src="/gallery/HLEB-Enterijer-03-07-11-thumb.jpeg" alt="Random Image"></figure>
						</a>
					</div>

					<div class="col-md-1 col-2 thumb">
						<a href="/gallery/HLEB-Enterijer-04-08-12.jpeg" title="HLEB-Enterijer-04-08-12">
							<figure><img class="img-fluid img-thumbnail" src="/gallery/HLEB-Enterijer-04-08-12-thumb.jpeg" alt="Random Image"></figure>
						</a>
					</div>
					<div class="col-md-1 col-2 thumb">
						<a href="/gallery/hleb_enterijer_01.jpeg" title="HLEB-Enterijer-recepcija">
							<figure><img class="img-fluid img-thumbnail" src="/gallery/HLEB-Enterijer-recepcija-thumb.jpeg" alt="Random Image"></figure>
						</a>
					</div>
				</div>
			</section>
	</div>
</template>
<script>
import $ from 'jquery'
import 'magnific-popup'
import 'magnific-popup/dist/magnific-popup.css'

export default {
  name: 'Gallery',
  mounted: function() {
      this.galleryPopup()
  },
  methods: {
		galleryPopup: function() {
			$(".gallery").magnificPopup({
				delegate: "a",
				type: "image",
				tLoading: "Loading image #%curr%...",
				mainClass: "mfp-img-mobile",
				gallery: {
					enabled: true,
					navigateByImgClick: true,
					preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
				},
			image: {
			tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
		}
		});
  }
  }
}
</script>